<template>
  <div>
    <v-form class="mt-4" :model="form" v-model="valid" ref="form">
      <v-row align="center" style="border-bottom:none !important;">
        <v-col cols="1">
          <v-subheader> 错误页标题</v-subheader>
        </v-col>
        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="errorTitle"
            required
            placeholder="请输入错误页标题"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader> 隐藏Logo</v-subheader>
        </v-col>
        <v-col cols="2" class="ml-6">
          <v-checkbox v-model="hideLogo"></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader>自定义CSS</v-subheader>
        </v-col>
        <v-col cols="6">
          <v-textarea
            rows="1"
            auto-grow
            v-model="errorStyle"
            persistent-hint
            hint="错误页面自定义CSS"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "LaunchpadSetting",
  data() {
    return {
      valid: true,
      form: {},
      errorTitle: "",
      hideLogo: false,
      errorStyle: ""
    };
  },
  created() {
    this.getHelp();
  },
  methods: {
    modifyHelp() {
      if (this.$refs.form.validate()) {
        let payload = {
          errorTitle: this.errorTitle,
          hideLogo: this.hideLogo,
          errorStyle: this.errorStyle
        };
        return this.$http
          .patch(`api/settings/help`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$emit("sonVal", false);
            return this.$snackbar.showMessage({
              content: `保存成功`,
              isError: false
            });
          })
          .catch(({ code, message }) => {
            throw `保存失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    getHelp() {
      this.$http
        .get(`api/settings/help`)
        .delegateTo(api_request)
        .then(data => {
          let { errorTitle, hideLogo, errorStyle } = data;
          this.errorTitle = errorTitle;
          this.hideLogo = hideLogo;
          this.errorStyle = errorStyle;
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-subheader {
  color: rgb(44, 62, 80);
}

/deep/.v-input__slot {
  padding-left: 10px;
}

/deep/ .v-label {
  font-size: 14px;
  color: rgb(44, 62, 80);
}
/deep/ .v-select__selections {
  font-size: 14px;
}

/deep/ .v-text-field__slot {
  font-size: 14px;
}

/deep/ .v-input__slot {
  padding-left: 5px;
}

.slider-item {
  /deep/ .v-input__slot {
    padding-left: 0px;
  }
}

.hint-style {
  padding-left: 30%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
