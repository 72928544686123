<template>
  <div>
    <v-form class="mt-4" :model="form" v-model="valid" ref="form">
      <v-row align="center" style="border-bottom:none !important;">
        <v-col cols="1">
          <v-subheader> 应用面板标题</v-subheader>
        </v-col>
        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="headerTitle"
            required
            placeholder="请输入标题"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader> 默认展示方式</v-subheader>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="cellDefaultMode"
            :items="cellDefaultModes"
            item-text="name"
            item-value="id"
            @change="cellDefaultModeSel(cellDefaultMode)"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader> 隐藏小图标模式ICON</v-subheader>
        </v-col>
        <v-col cols="2" class="ml-6">
          <v-checkbox
            v-model="cellHideIcon"
            @change="cellHideIconSel(cellHideIcon)"
            persistent-hint
            hint="隐藏长方形小图标模式下的ICON"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader> 隐藏WebExp应用标记</v-subheader>
        </v-col>
        <v-col cols="2" class="ml-6">
          <v-checkbox
            v-model="cellWebexpBadge"
            @change="cellWebexpBadgeSel(cellWebexpBadge)"
            persistent-hint
            hint="为WebExp应用隐藏标头"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader>自定义CSS</v-subheader>
        </v-col>
        <v-col cols="6">
          <v-textarea
            rows="1"
            auto-grow
            v-model="loginStyle"
            persistent-hint
            hint="应用面板自定义CSS"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row align="start">
        <v-col cols="1">
          <v-subheader> 应用面板样式</v-subheader>
        </v-col>
        <v-col cols="2" class="mt-4">
          <v-text-field
            v-model="headerBgColor"
            hide-details
            class="ma-0 pa-0"
            solo
          >
            <template v-slot:append>
              <v-menu
                v-model="navigatorBgMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div :style="navigatorBgStyle" v-on="on" />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="headerBgColor" flat />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
          <span class="hint-style">应用顶部背景颜色</span>
        </v-col>
        <v-col cols="2" class="mt-4">
          <v-text-field
            v-model="cellBgColor"
            hide-details
            class="ma-0 pa-0"
            solo
          >
            <template v-slot:append>
              <v-menu
                v-model="applicationBgMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div :style="applicationBgStyle" v-on="on" />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="cellBgColor" flat />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
          <span class="hint-style">应用图标区域背景色</span>
        </v-col>
      </v-row>
      <v-row align="center" style="border-bottom:none !important;">
        <v-col cols="1">
          <v-subheader> 默认应用</v-subheader>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="autorunApplication"
            :items="autorunApplications"
            item-text="name"
            item-value="id"
            @change="autorunApplicationSel(autorunApplication)"
            hint="用户登录后，直接跳转至指定应用"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "LaunchpadSetting",
  data() {
    return {
      valid: true,
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"]
      },
      headerTitle: "",
      autorunApplication: "",
      loginStyle: "",
      autorunApplications: ["不自动访问应用"],
      cellDefaultMode: "tile",
      cellDefaultModes: [
        { id: "tile", name: "长方形小图标" },
        { id: "square", name: "正方形大图标" }
      ],
      cellWebexpBadge: false,
      cellHideIcon: false,
      headerBgColor: "#FFFFFF",
      navigatorBgMenu: false,
      cellBgColor: "#FFFFFF",
      applicationBgMenu: false
    };
  },
  async created() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    await this.getApplications();
    await this.fetchApplication();
  },
  methods: {
    autorunApplicationSel(autorunApplication) {
      this.autorunApplication = autorunApplication;
    },
    cellDefaultModeSel(cellDefaultMode) {
      this.cellDefaultMode = cellDefaultMode;
    },
    cellWebexpBadgeSel(cellWebexpBadge) {
      this.cellWebexpBadge = cellWebexpBadge;
    },
    cellHideIconSel(cellHideIcon) {
      this.cellHideIcon = cellHideIcon;
    },
    fetchApplication() {
      return this.$http
        .get(`api/settings/launchpad`)
        .delegateTo(api_request)
        .then(res => {
          let {
            headerTitle,
            loginStyle,
            autorunApplication,
            cellDefaultMode,
            cellWebexpBadge,
            cellHideIcon,
            headerBgColor,
            cellBgColor
          } = res;
          this.headerTitle = headerTitle;
          if (loginStyle) {
            let styleText = loginStyle;
            let curstyle = document.createElement("style");
            curstyle.innerText = styleText;
            document.getElementsByTagName("body")[0].appendChild(curstyle);
          }
          this.autorunApplication =
            autorunApplication != null ? autorunApplication : "不选择";
          this.cellDefaultMode = cellDefaultMode ? cellDefaultMode : "tile";
          this.cellWebexpBadge = cellWebexpBadge ? cellWebexpBadge : false;
          this.cellHideIcon = cellHideIcon ? cellHideIcon : false;
          this.headerBgColor = headerBgColor ? headerBgColor : "#FFFFFF";
          this.cellBgColor = cellBgColor ? cellBgColor : "#FFFFFF";
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    modifyApplication() {
      if (this.$refs.form.validate()) {
        let payload = {
          headerTitle: this.headerTitle,
          loginStyle: this.loginStyle,
          autorunApplication:
            this.autorunApplication !== "不选择"
              ? this.autorunApplication
              : null,
          cellDefaultMode: this.cellDefaultMode,
          cellWebexpBadge: this.cellWebexpBadge,
          cellHideIcon: this.cellHideIcon,
          headerBgColor: this.headerBgColor,
          cellBgColor: this.cellBgColor
        };
        return this.$http
          .patch(`api/settings/launchpad`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$emit("sonVal", false);
            return this.$snackbar.showMessage({
              content: `保存成功`,
              isError: false
            });
          })
          .catch(({ code, message }) => {
            throw `保存失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    getApplications() {
      this.loading = true;
      this.$http
        .get(`api/applications`)
        .delegateTo(api_request)
        .then(data => {
          if (data.length > 0) {
            this.autorunApplications = data.filter(
              item => item.type !== "WebEXP"
            );
            this.autorunApplications.unshift("不选择");
          }
        })
        .catch(({ code, message }) => {
          this.loading = false;
          throw `获取应用列表数据失败：${this.$t(
            "api." + code
          )}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  computed: {
    navigatorBgStyle() {
      const { headerBgColor, navigatorBgMenu } = this;
      return {
        backgroundColor: headerBgColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: navigatorBgMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    },
    applicationBgStyle() {
      const { cellBgColor, applicationBgMenu } = this;
      return {
        backgroundColor: cellBgColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: applicationBgMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-subheader {
  color: rgb(44, 62, 80);
}

/deep/.v-input__slot {
  padding-left: 10px;
}

/deep/ .v-label {
  font-size: 14px;
  color: rgb(44, 62, 80);
}
/deep/ .v-select__selections {
  font-size: 14px;
}

/deep/ .v-text-field__slot {
  font-size: 14px;
}

/deep/ .v-input__slot {
  padding-left: 5px;
}

.slider-item {
  /deep/ .v-input__slot {
    padding-left: 0px;
  }
}

.hint-style {
  padding-left: 30%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
