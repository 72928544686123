<template>
  <div class="personal-wrapper">
    <Breadcrumbs>
      <template slot="action-buttons">
        <v-btn
          v-show="isShow"
          class="pl-5 pr-5"
          depressed
          color="primary"
          @click="saveData()"
          >保存</v-btn
        >
      </template>
    </Breadcrumbs>
    <div class="setting_wrapper">
      <TabList
        class="tablist"
        @tabEvent="tabEvent($event)"
        :tabList="tabList"
      />
      <div>
        <component :is="curCom" ref="component" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import TabList from "@/components/TabList";
import LoginSetting from "./component/LoginSetting";
import LaunchpadSetting from "./component/LaunchpadSetting";
import MLoginSetting from "./component/MLoginSetting";
import HelpSetting from "./component/HelpSetting";

export default {
  name: "Personalization",
  data() {
    return {
      isShow: true,
      tabId: 0,
      tabList: [
        {
          id: 0,
          name: "PC端登录页"
        },
        {
          id: 1,
          name: "应用面板"
        },
        {
          id: 2,
          name: "移动端登录页"
        },
        {
          id: 3,
          name: "错误页设置"
        }
      ],
      curCom: "LoginSetting"
    };
  },
  methods: {
    tabEvent(id) {
      this.tabId = id;
    },
    saveData() {
      if (this.tabId === 0) {
        this.$refs.component.modifyPreLoginStyle();
      } else if (this.tabId === 1) {
        this.$refs.component.modifyApplication();
      } else if (this.tabId === 2) {
        this.$refs.component.modifyMLoginStyle();
      } else if (this.tabId === 3) {
        this.$refs.component.modifyHelp();
      }
    }
  },
  watch: {
    tabId(v) {
      this.curCom = [
        LoginSetting,
        LaunchpadSetting,
        MLoginSetting,
        HelpSetting
      ][v];
    }
  },
  components: {
    Breadcrumbs,
    TabList,
    LoginSetting,
    LaunchpadSetting,
    MLoginSetting,
    HelpSetting
  }
};
</script>
<style lang="less" scoped>
.personal-wrapper {
  .setting_wrapper {
    margin: 12px 12px 0 24px;
  }
}
</style>
